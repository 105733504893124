var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"reservation-list",staticStyle:{"border-radius":"0","background-color":"blue"},attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-0 custom-table",attrs:{"headers":_vm.headers,"items":_vm.tableData,"hide-default-footer":"","loading":_vm.loading,"expanded":_vm.expanded,"show-expand":"","item-key":"ID","items-per-page":_vm.itemsPerPage},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('template-detail',{attrs:{"item":item,"locale":_vm.locale,"onEdit":_vm.handleEditItem}})],1)]}},{key:"item.ident",fn:function(ref){
var item = ref.item;
return [(_vm.onEditItem)?_c('a',{staticClass:"ident",on:{"click":function($event){return _vm.handleEditItem(item)}}},[_c('p',[_vm._v(_vm._s(item.Owner))]),_c('p',[_vm._v(_vm._s(item.Email))])]):_vm._e()]}},{key:"item.NumPax",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.PaxData)}})]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(!item.Status)?_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.$t("booking.reservations.status.default", _vm.locale).toUpperCase())+" ")]):(item.Status === 'CANCELED')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white","small":""}},[_vm._v(" "+_vm._s(_vm.$t(("booking.reservations.status." + (item.Status.toLowerCase())), _vm.locale).toUpperCase())+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.onEditItem && !item.NotEditable)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEditItem(item.ID)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.onDeleteItem)?_c('delete-item',{attrs:{"id":item.ID,"onDeleteItem":_vm.onDeleteItem,"icon":"mdi-delete","locale":_vm.locale}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticStyle:{"margin-top":"20px"},attrs:{"border":"bottom","color":"primary","outlined":"","dense":""}},[_vm._v(" "+_vm._s(_vm.$t('booking.reservations.messages.noData', _vm.locale))+" "),(_vm.onEditItem && !_vm.hideAddOnNoData)?_c('v-btn',{staticStyle:{"margin-left":"20px"},on:{"click":function($event){return _vm.onEditItem(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.newItem', _vm.locale))+" ")]):_vm._e()],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }