<template>
  <div style="border-radius: 0 4px 4px 0;background-color: white;min-height: calc(100vh - 104px);">
        <v-toolbar
          elevation="1"
          style="border-radius: 0 4px 0 0;"
        >
          <v-icon style="margin-right: 10px;">mdi-bookmark-multiple</v-icon>
          <v-toolbar-title>
            {{ $t('booking.reservations.title', locale) }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            style="margin-left:20px;margin-right:20px; "
            color="primary"
            @click="handleGetData(startIndex)"
          >
            {{ $t('booking.reservations.refresh', locale) }}
          </v-btn>
            <search :onSearch="handleSearch" />
        </v-toolbar>
        <v-toolbar
          v-if="isRelocateReservations"
          elevation="1"
          style="border-radius: 0 4px 0 0;padding-bottom: 20px;"
        >
          <v-select
            v-model="selectedStates"
            :items="states"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('booking.reservations.status.title', locale)"
            item-text="tName"
            item-value="id"
            multiple
            @change="handleChangeFilter"
            outlined
            dense
            style="max-width:200px;"
          />
          <v-spacer />
        </v-toolbar>
        <v-toolbar
          v-else-if="!isRelocateReservations"
          elevation="1"
          style="border-radius: 0 4px 0 0;padding-bottom: 20px;"
        >
          <v-select
            v-model="selectedStates"
            :items="states"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('booking.reservations.status.title', locale)"
            item-text="tName"
            item-value="id"
            multiple
            @change="handleChangeFilter"
            outlined
            dense
            style="max-width:200px;"
          />
          <v-select
            v-if="activities && activities.length > 0"
            v-model="selectedActivities"
            :items="activities"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('booking.reservations.activities', locale)"
            item-text="tName"
            item-value="id"
            multiple
            @change="handleChangeActivities"
            outlined
            dense
            style="max-width:200px;margin-left: 20px;"
          />
          <v-select
            v-if="events && events.length > 0"
            v-model="selectedEvents"
            :items="events"
            :menu-props="{ maxHeight: '400' }"
            :label="$t('booking.reservations.events', locale)"
            item-text="name"
            item-value="id"
            multiple
            @change="handleChangeEvents"
            outlined
            dense
            style="max-width:200px;margin-left: 20px;"
          />
          <v-spacer />
        </v-toolbar>
        <custom-table-expandable
          :loading="loading"
          :headers="isRelocateReservations ? 
          [
            { text: $t('booking.reservations.owner', locale), value: 'ident' },
            { text: $t('booking.reservations.activity', locale), value: 'ActivityName' },
            { text: $t('booking.reservations.date', locale), value: 'Date', align: 'center' },
            { text: $t('booking.reservations.time', locale), value: 'Time', align: 'center' },
            { text: $t('booking.reservations.pax_', locale), value: 'NumPax', align: 'start' },
            { text: $t('booking.reservations.status.title', locale), value: 'Status', align: 'center' },
            { text: '', value: 'data-table-expand' },
          ] : [
            { text: $t('booking.reservations.owner', locale), value: 'ident' },
            { text: $t('booking.reservations.activity', locale), value: 'ActivityName' },
            { text: $t('booking.reservations.time', locale), value: 'Time', align: 'end' },
            { text: $t('booking.reservations.pax_', locale), value: 'NumPax', align: 'start' },
            { text: $t('booking.reservations.status.title', locale), value: 'Status', align: 'center' },
            { text: '', value: 'data-table-expand' },
          ]"
          :onSearch="handleSearch"
          :onGetData="handleGetData"
          :onEditItem="handleEditItem"
          :tableData="reservations"
          :startIndex="startIndex"
          :itemsPerPage="itemsPerPage"
          :count="count"
          hideAddOnNoData
        />
    <pagination 
      v-if="count >= itemsPerPage || itemsPerPage !== 5"
      :count="count"
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :locale="locale"
      :onGetData="handleGetData"
      :onChangeItemsPerPage="handleChangeItemsPerPage"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import api from '@/services/api'
import CustomTableExpandable from '@/components/customTableExpandable/Index'
import Search from '@/components/Search'
import Pagination from './Pagination'
export default {
  name: 'ActivityList',
  components: {
    CustomTableExpandable,
    Search,
    Pagination,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
    onChangeQuery: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    isRelocateReservations: true,
    reservations: [],
    count: 0,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',
    selectedStates: ['RESERVED'],
    states: [{
        id: 'RESERVED',
        name: { es: 'Reservado' },
        tName: 'Reservado'
      },
      {
        id: 'CANCELED',
        name: { es: 'Cancelado' },
        tName: 'Cancelado'
    }],
    selectedActivities: [],
    activities: [],
    selectedEvents: [],
    events: [],
  }),
  computed: {
    ...mapState('app',['bookingWorkspace']),
    dragOptionsDisabled() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    bookingWorkspace () {
      this.handleGetData(0)
    },
    selectedDate () {
      this.selectedActivities = []
      this.selectedEvents = []
      this.handleGetData(0)
      this.handleGetEvents()
    },
    'updateData.LMD' () {
      this.handleGetData(0)
    },
  },
  created () {
    this.states = this.states.map(item => {
      item.tName = this.getTranslation(item.name, this.locale)
      return item
    })
  },
  mounted () {
    this.isRelocateReservations = this.$route.meta.mainPath === 'relocateReservations'
    this.handleGetActivities()
    this.handleGetEvents()
    this.handleGetData(0)
  },
  methods: {
    handleGetActivities () {
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/activities`, 0, 10000, '')
        .then (response => {
          this.activities = response.data.map( item => { return { id: item.ID, name: JSON.parse(item.Name), tName: this.getTranslation(JSON.parse(item.Name), this.locale) } } )
        })
    },
    getLastPage (itemsPerPage, count) {
      const aux = count / itemsPerPage
      const p = parseInt(aux, 10)
      return (aux % 1 > 0 ? p : p-1) * itemsPerPage
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData (startIndex) {
      if (this.isRelocateReservations) this.handleGetRelocateReservations(startIndex)
      else this.handleGetReservations (startIndex)
    },
    handleGetEvents () {
      this.events = []
      let query = `${this.search ? this.search : ''}&date=${this.selectedDate}`
      if (this.selectedStates.length > 0) query += `&states=${this.selectedStates.join(',')}`
      if (this.selectedActivities.length > 0) query += `&activities=${this.selectedActivities.join(',')}`

      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/reservations-times`, 0, 1000, query)
        .then(response => {
          this.events = response.data.map(item => {
            return {
              id: item.Time,
              name: utils.convertTime(item.Time)
            }
          })
        })
    },
    prepareCustomPaxData (item) {
      let result = ''
      if (!item.Adult && !item.Children && !item.Baby) return item.NumPax
      if (item.Adult) result += `<p style="margin:0"><b>A: </b>${item.Adult}</p>`
      if (item.Children) result += `<p style="margin:0"><b>C: </b>${item.Children}</p>`
      if (item.Baby) result += `<p style="margin:0"><b>B: </b>${item.Baby}</p>`
      return result
    },
    handleGetRelocateReservations (startIndex) {
      this.reservations = []
      this.startIndex = startIndex
      const query = `${this.search ? this.search : ''}`

      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/relocate-event-reservations`, this.startIndex, this.itemsPerPage, query)
        .then(response => {
          this.count = response.count
          response.data = response.data.map(item => {
            item.Status = item.Status ? item.Status : 'RESERVED'
            return item
          })
          this.reservations = response.data.filter(x => this.selectedStates.indexOf(x.Status) >= 0).map(item => {
            let name = item.OwnerName || item.OwnerLastName ? `<p>${item.OwnerName ? item.OwnerName : ''} ${item.OwnerLastName ? item.OwnerLastName : ''}</p>` : ''
            if (item.OwnerEmail) name += `<p>${item.OwnerEmail}</p>`
            return {
              ident: {
                id: item.ID,
                name,
              },
              ID: item.ID,
              ActivityName: utils.getTranslation(item.ActivityName, this.locale),
              Date: item.Date.split('T').shift(),
              Time: utils.convertTime(item.Time),
              Owner: `${item.OwnerName ? item.OwnerName : ''} ${item.OwnerLastName ? item.OwnerLastName : ''}`,
              Email: item.OwnerEmail,
              NotEditable: false,
              ISRelocate: true,
              Status: item.Status,
              Event: {
                ID: item.BookedEventID,
                Source: item.Source,
                WorkspaceID: item.WorkspaceID,
              },
              PaxData: this.prepareCustomPaxData(item),
              NumPax: item.NumPax,
              FieldsValues: item.FieldsValues ? JSON.parse(item.FieldsValues) : null,
              CU: item.CU,
              LMU: item.LMU,
            }
          })
          this.loading = false
        })
    },
    handleGetReservations (startIndex) {
      this.reservations = []
      this.startIndex = startIndex
      let query = `${this.search ? this.search : ''}&date=${this.selectedDate}`
      if (this.selectedStates.length > 0) query += `&states=${this.selectedStates.join(',')}`
      if (this.selectedActivities.length > 0) query += `&activities=${this.selectedActivities.join(',')}`
      if (this.selectedEvents.length > 0) query += `&events=${this.selectedEvents.join(',')}`

      if (this.onChangeQuery) this.onChangeQuery(query)

      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/reservations`, this.startIndex, this.itemsPerPage, query)
        .then(response => {
          this.count = response.count
          this.reservations = response.data.map(item => {
            return {
              ident: {
                id: item.ID,
                name: `
                        <p>${item.OwnerName} ${item.OwnerLastName ? item.OwnerLastName : ''}</p>
                        <p>${item.OwnerEmail}</p>
                      `,
              },
              ID: item.ID,
              ActivityName: `${item.Source === 'acttiv' ? '⚠ ' : '' }` + utils.getTranslation(item.ActivityName, this.locale),
              Time: utils.convertTime(item.Time),
              Owner: item.OwnerName || item.OwnerLastName ? `${item.OwnerName} ${item.OwnerLastName ? item.OwnerLastName : ''}` : item.OwnerRoom,
              Email: item.OwnerEmail,
              NotEditable: item.Source === 'acttiv' && item.Status === 'CANCELED',
              Status: item.Status,
              Event: {
                IsActtiv: item.Source === 'acttiv',
                ID: item.BookedEventID,
                Source: item.Source,
                WorkspaceID: item.WorkspaceID,
                SeatsByBooking: item.SeatsByBooking,
              },
              PaxData: this.prepareCustomPaxData(item),

              NumPax: item.NumPax,
              FieldsValues: item.FieldsValues ? JSON.parse(item.FieldsValues) : null,
              IsActtiv: item.Source === 'acttiv',
              MultiReservationID: item.MultiReservationID,
              ResourceItemID: item.ResourceItemID,
              CU: item.CU,
              LMU: item.LMU,
            }
          })
          this.loading = false
        })
    },
    handleEditItem (id) {
      const selectedItem = this.reservations.filter(x =>x.ID === id).shift()
      if (selectedItem) this.onEdit(id, selectedItem.Event)
    },
    handleDeleteItem (v) {
      api.deleteItem ('booking', `v1/private/reservations/`, v)
        .then(() => {
          this.handleGetData(0)
        })
    },
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleChangeItemsPerPage(v) {
      this.itemsPerPage = v
      this.handleGetData(0)
    },
    handleChangeFilter () {
      this.handleGetData(0)
    },
    handleChangeActivities () {
      this.selectedEvents = []
      this.handleGetData(0)
      this.handleGetEvents()
    },
    handleChangeEvents () {
      this.handleGetData(0)
    },
  },
}
</script>
<style scoped>
.itemDraggable .title {
}
</style>

