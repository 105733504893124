<template>
  <v-card elevation="1" style="border-radius: 0; background-color: blue;"
    class="reservation-list"
  >
    <v-data-table
      :headers="headers"
      :items="tableData"
      class="elevation-0 custom-table"
      hide-default-footer
      :loading="loading"
      :expanded.sync="expanded"
      show-expand
      item-key="ID"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <template-detail
            :item="item"
            :locale="locale"
            :onEdit="handleEditItem"
          />
        </td>
      </template>
      <template v-slot:[`item.ident`]="{ item }">
        <a class="ident" v-if="onEditItem" @click="handleEditItem(item)">
          <p>{{item.Owner}}</p>
          <p>{{item.Email}}</p>
        </a>
      </template>
      <template v-slot:[`item.NumPax`]="{ item }">
        <div v-html="item.PaxData" />
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <v-chip
          v-if="!item.Status"
          class="ma-2"
          small
        >
          {{ $t(`booking.reservations.status.default`, locale).toUpperCase() }}
        </v-chip>
        <v-chip
          v-else-if="item.Status === 'CANCELED'"
          class="ma-2"
          color="red"
          text-color="white"
          small
        >
          {{ $t(`booking.reservations.status.${item.Status.toLowerCase()}`, locale).toUpperCase() }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="onEditItem && !item.NotEditable"
          small
          class="mr-2"
          @click="onEditItem(item.ID)"
        >
          mdi-pencil
        </v-icon>
        <delete-item
          v-if="onDeleteItem"
          :id="item.ID"
          :onDeleteItem="onDeleteItem"
          icon="mdi-delete"
          :locale="locale"
        />
      </template>
      <template v-slot:no-data>        
        <v-alert
          v-show="!loading"
          border="bottom"
          color="primary"
          style="margin-top:20px"
          outlined
          dense
        >
          {{ $t('booking.reservations.messages.noData', locale) }}
          <v-btn
            v-if="onEditItem && !hideAddOnNoData"
            @click="onEditItem(null)"
            style="margin-left: 20px;"
          >
          {{ $t('common.newItem', locale) }}
          </v-btn>
        </v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
  import { mapState } from 'vuex'
  import TemplateDetail from './TemplateDetail'
  import DeleteItem from '../DeleteItem'
  export default {
    components: {
      TemplateDetail,
      DeleteItem,
    },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: null
      },
      headers: {
        type: Array,
        required: true
      },
      tableData: {
        type: Array,
        required: true
      },
      startIndex: {
        type: Number,
        required: false,
        default: null
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      itemsPerPage: {
        type: Number,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: true,
        default: null
      },
      onEditItem: {
        type: Function,
        required: false,
        default: null
      },
      onDeleteItem: {
        type: Function,
        required: false,
        default: null
      },
      onSearch: {
        type: Function,
        default: null
      },
      hideAddOnNoData: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      expanded: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),
    computed: {
      ...mapState('app',['locale', 'bookingRefreshReservationList']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      bookingRefreshReservationList () {
        this.onSearch()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      handleEditItem (item) {
        if (item.NotEditable) {
          alert (this.$t('booking.reservations.messages.statusCanceledNoEditable', this.locale))
          return
        }
        this.onEditItem(item.ID)
      },
      initialize () {
      },

      editItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tableData.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.tableData.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.tableData[this.editedIndex], this.editedItem)
        } else {
          this.tableData.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>
<style>
.ident p {
  line-height: 1;
  margin: 5px;
}
  .v-data-table.custom-table th span {
    font-weight: 500 !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 1rem !important;
    letter-spacing: .009375em !important;
    line-height: 1.75rem;
  }

  .reservation-list .v-data-table__wrapper {
    overflow-y: auto;
    max-height: calc(-234px + 100vh);
  }
  .reservation-list table {
    overflow-y: auto;
  }
</style>
